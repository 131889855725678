import React, { cloneElement } from 'react';
import {
  Show,
  ShowButton,
  SimpleShowLayout,
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  SimpleForm,
  TextInput,
  Filter,
  AutocompleteInput,
  CreateButton,
  useListContext,
  ExportButton,
  DateField,
  SelectInput,
  sanitizeListRestProps,
} from 'react-admin';
import { Button, Toolbar as MuiToolbar } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { JsonField, JsonInput } from 'react-admin-json-view';

import useCountries from '../../hooks/useCountries';
import useNames from '../../hooks/useNames';
import usePops from '../../hooks/usePops';
import useAges from '../../hooks/useAges';
import useTypes from '../../hooks/useTypes';

const WidDataFilter = (props) => {
  const { data: popsList } = usePops();
  const { data: typesList } = useTypes();
  const { data: agesList } = useAges();
  const { data: countriesList } = useCountries();
  const { data: namesData } = useNames();

  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <SelectInput
        source="country"
        choices={countriesList}
        optionText="alpha2"
        optionValue="alpha2"
      />
      <SelectInput
        source="s_type"
        choices={typesList}
        optionText={(record) =>
          record && `${record.onetype} (${record.shortdes})`
        }
        optionValue="onetype"
      />
      <SelectInput
        source="y"
        choices={namesData.uniqueY}
        optionText={(record) =>
          record && `${record.twolet} (${record.shortname})`
        }
        optionValue="twolet"
      />
      <SelectInput
        source="var"
        choices={namesData.uniqueVar}
        optionText={(record) =>
          record && `${record.threelet} (${record.shortname})`
        }
        optionValue="threelet"
      />
      <SelectInput
        source="age"
        choices={agesList}
        optionText={(record) =>
          record && `${record.agecode} (${record.shortname})`
        }
        optionValue="agecode"
      />
      <SelectInput
        source="pop"
        choices={popsList}
        optionText={(record) =>
          record && `${record.onepop} (${record.shortdes})`
        }
        optionValue="onepop"
      />
    </Filter>
  );
};

const WidDataListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  return (
    <MuiToolbar
      className={className}
      style={{ alignItems: 'center' }}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      <Button
        startIcon={<CloudUpload />}
        color="primary"
        variant="text"
        href="#/wid_data/import"
      >
        Import
      </Button>
    </MuiToolbar>
  );
};

export const WidDataList = (props) => (
  <List
    {...props}
    filters={<WidDataFilter />}
    actions={<WidDataListActions />}
    sort={{ field: 'modified', order: 'DESC' }}
  >
    <Datagrid>
      <TextField source="country" />
      <TextField source="s_type" />
      <TextField source="y" />
      <TextField source="var" />
      <TextField source="age" />
      <TextField source="pop" />
      <TextField source="perc" />
      <TextField source="filename" />
      <EditButton />
      <ShowButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

const WidDataTitle = ({ record }) => {
  return <span>Editing wid_data: {record ? `[${record.country}]` : ''}</span>;
};

export const WidDataEdit = (props) => {
  return (
    <Edit title={<WidDataTitle />} {...props}>
      <WidDataFormInputs {...props} />
    </Edit>
  );
};

const WidDataFormInputs = ({ record, ...rest }) => {
  const { data: popsList, loading: popsLoading } = usePops();
  const { data: typesList, loading: typesLoading } = useTypes();
  const { data: agesList, loading: agesLoading } = useAges();
  const { data: countriesList, loading: countriesLoading } = useCountries();
  const { data: namesData, loading: namesLoading } = useNames();

  return (
    <SimpleForm warnWhenUnsavedChanges initialValues={record} {...rest}>
      <AutocompleteInput
        loading={countriesLoading}
        resettable
        source="country"
        choices={countriesList}
        optionText="alpha2"
        optionValue="alpha2"
      />
      <AutocompleteInput
        loading={typesLoading}
        resettable
        source="s_type"
        choices={typesList}
        optionText={(record) =>
          record && `${record.onetype} (${record.shortdes})`
        }
        optionValue="onetype"
      />
      <AutocompleteInput
        loading={namesLoading}
        resettable
        source="y"
        choices={namesData.uniqueY}
        optionText={(record) =>
          record && `${record.twolet} (${record.shortname})`
        }
        optionValue="twolet"
      />
      <AutocompleteInput
        loading={namesLoading}
        resettable
        source="var"
        choices={namesData.uniqueVar}
        optionText={(record) =>
          record && `${record.threelet} (${record.shortname})`
        }
        optionValue="threelet"
      />
      <AutocompleteInput
        loading={agesLoading}
        resettable
        source="age"
        choices={agesList}
        optionText={(record) =>
          record && `${record.agecode} (${record.shortname})`
        }
        optionValue="agecode"
      />
      <AutocompleteInput
        loading={popsLoading}
        resettable
        source="pop"
        choices={popsList}
        optionText={(record) =>
          record && `${record.onepop} (${record.shortdes})`
        }
        optionValue="onepop"
      />
      <TextInput source="perc" />
      <JsonInput
        source="year_value"
        reactJsonOptions={{
          name: 'values',
          enableClipboard: true,
          displayDataTypes: false,
        }}
      />
    </SimpleForm>
  );
};

export const WidDataCreate = (props) => (
  <Create {...props}>
    <WidDataFormInputs />
  </Create>
);

export const WidDataShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="country" />
      <TextField source="s_type" />
      <TextField source="y" />
      <TextField source="var" />
      <TextField source="age" />
      <TextField source="pop" />
      <TextField source="perc" />
      <TextField source="filename" />
      <DateField source="modified" showTime locales="fr-FR" />
      <JsonField
        source="year_value"
        addLabel
        reactJsonOptions={{
          name: 'values',
          enableClipboard: true,
          displayDataTypes: false,
        }}
      />
    </SimpleShowLayout>
  </Show>
);
