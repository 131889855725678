import { useGetList } from 'react-admin';

const useTree = () => {
  const { data, ...rest } = useGetList(
    'variables_tree',
    {
      page: 1,
      perPage: 2000,
    },
    { field: 'path', order: 'ASC' }
  );

  const treeList = Object.values(data);

  const uniquePaths = treeList.reduce(
    (acc, x) => acc.concat(acc.find((y) => y.path === x.path) ? [] : [x]),
    []
  );

  const uniqueComps = treeList.reduce(
    (acc, x) => acc.concat(acc.find((y) => y.comp === x.comp) ? [] : [x]),
    []
  );

  const uniqueLevels = treeList.reduce(
    (acc, x) => acc.concat(acc.find((y) => y.level === x.level) ? [] : [x]),
    []
  );

  const uniqueCategories = treeList.reduce(
    (acc, x) =>
      acc.concat(acc.find((y) => y.category === x.category) ? [] : [x]),
    []
  );

  const uniqueNames = treeList.reduce(
    (acc, x) => acc.concat(acc.find((y) => y.name === x.name) ? [] : [x]),
    []
  );

  const uniqueRanks = treeList.reduce(
    (acc, x) => acc.concat(acc.find((y) => y.rank === x.rank) ? [] : [x]),
    []
  );

  return {
    ...rest,
    data: {
      uniquePaths,
      uniqueComps,
      uniqueLevels,
      uniqueCategories,
      uniqueNames,
      uniqueRanks,
    },
  };
};

export default useTree;
