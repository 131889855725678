/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:f919688d-be6e-4634-8785-ff95cd9e127e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_lc81W3dbG",
    "aws_user_pools_web_client_id": "4omuq46jh16ql73119vkppptff",
    "oauth": {}
};


export default awsmobile;
