import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify, Login } from 'react-admin';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CustomLoginForm from './CustomLoginForm';

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: 'linear-gradient(120deg, #1c6179 0%, #f68084 100%)',
    },
    card: {
      minWidth: 500,
      marginTop: '20em',
      padding: '2em',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  }),
  { name: 'RaLoginOverride' }
);

const LoginPage = (props) => {
  const classesOverride = useStyles(props);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ email, password }).catch(() => notify('Invalid email or password'));
  };

  return (
    <Login {...props} classes={classesOverride}>
      <Typography variant="h5" style={{ textAlign: 'center', fontWeight: 700 }}>
        WID ADMIN
      </Typography>
      <CustomLoginForm onSubmit={handleSubmit}>
        <input
          name="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          name="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </CustomLoginForm>
    </Login>
  );
};

export default LoginPage;
