import React, {cloneElement} from 'react';
import {
    Show,
    ShowButton,
    SimpleShowLayout,
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    EditButton,
    DeleteButton,
    SimpleForm,
    TextInput,
    Filter,
    required,
    maxLength,
    CreateButton,
    ExportButton,
    useListContext,
    NumberInput,
    NumberField,
    number, SelectInput, sanitizeListRestProps,
} from 'react-admin';
import {CloudUpload} from '@material-ui/icons';
import {Button, Toolbar as MuiToolbar} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import useTypes from "../../hooks/useTypes";

const validateOneType = [required(), maxLength(1)];
const validateRank = [number()];

const VariablesTypesFilter = (props) => {
    const {data: typesList} = useTypes();

    return (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn/>
            <SelectInput
                source="onetype"
                choices={typesList}
                optionText="onetype"
                optionValue="onetype"
            />
            <SelectInput
                source="shortdes"
                choices={typesList}
                optionText="shortdes"
                optionValue="shortdes"
            />
        </Filter>
    );
}

const VariablesTypesListActions = (props) => {
    const {className, exporter, filters, maxResults, ...rest} = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total,
    } = useListContext();

    return (
        <MuiToolbar
            className={className}
            style={{alignItems: 'center'}}
            {...sanitizeListRestProps(rest)}>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <CreateButton basePath={basePath}/>
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
            <Button
                startIcon={<CloudUpload/>}
                color="primary"
                variant="text"
                href="#/variables_types/import"
            >
                Import
            </Button>
        </MuiToolbar>
    );
};

const useStyles = makeStyles(() => ({
    tableCell: {
        maxWidth: 300,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
}));

export const VariablesTypesList = (props) => {
    const classes = useStyles();

    return (
        <List
            {...props}
            filters={<VariablesTypesFilter/>}
            actions={<VariablesTypesListActions/>}
            sort={{field: 'modified', order: 'DESC'}}
        >
            <Datagrid>
                <TextField source="onetype"/>
                <TextField source="shortdes"/>
                <TextField cellClassName={classes.tableCell} source="longdes"/>
                <TextField cellClassName={classes.tableCell} source="onlinedes"/>
                <TextField source="onlinedes2"/>
                <NumberField source="rank"/>
                <TextField source="filename"/>
                <EditButton/>
                <ShowButton/>
                <DeleteButton/>
            </Datagrid>
        </List>
    );
};

const VariablesTypesTitle = ({record}) => {
    return (
        <span>
      Editing variable type:{' '}
            <strong>{record ? `(${record.onetype})${record.shortdes}` : ''}</strong>
    </span>
    );
};

export const VariablesTypesEdit = (props) => (
    <Edit title={<VariablesTypesTitle/>} {...props}>
        <SimpleForm warnWhenUnsavedChanges>
            <TextInput source="onetype" validate={validateOneType}/>
            <TextInput multiline source="shortdes"/>
            <TextInput multiline source="longdes"/>
            <TextInput multiline source="onlinedes"/>
            <TextInput multiline source="onlinedes2"/>
            <NumberInput source="rank" validate={validateRank}/>
        </SimpleForm>
    </Edit>
);

export const VariablesTypesCreate = (props) => (
    <Create {...props}>
        <SimpleForm warnWhenUnsavedChanges>
            <TextInput source="onetype" validate={validateOneType}/>
            <TextInput multiline source="shortdes"/>
            <TextInput multiline source="longdes"/>
            <TextInput multiline source="onlinedes"/>
            <TextInput multiline source="onlinedes2"/>
            <NumberInput source="rank" validate={validateRank}/>
        </SimpleForm>
    </Create>
);

export const VariablesTypesShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="onetype"/>
            <TextField source="shortdes"/>
            <TextField source="longdes"/>
            <TextField source="onlinedes"/>
            <TextField source="onlinedes2"/>
            <NumberField source="rank"/>
            <TextField source="filename"/>
        </SimpleShowLayout>
    </Show>
);
