import React, { cloneElement } from 'react';
import {
  Show,
  ShowButton,
  SimpleShowLayout,
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  SimpleForm,
  TextInput,
  Filter,
  required,
  CreateButton,
  ExportButton,
  useListContext,
  NumberInput,
  NumberField,
  number,
  BooleanField,
  BooleanInput,
  sanitizeListRestProps,
  SelectInput,
} from 'react-admin';
import { CloudUpload } from '@material-ui/icons';
import { Button, Toolbar as MuiToolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useTree from '../../hooks/useTree';

const validate = [required()];
const validateRank = [number()];

const VariablesTreeFilter = (props) => {
  const {
    data: {
      uniquePaths,
      uniqueComps,
      uniqueLevels,
      uniqueCategories,
      uniqueNames,
      uniqueRanks,
    },
  } = useTree();

  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <SelectInput
        source="path"
        choices={uniquePaths}
        optionText="path"
        optionValue="path"
      />
      <SelectInput
        source="comp"
        choices={uniqueComps}
        translateChoice={false}
        optionText="comp"
        optionValue="comp"
      />
      <SelectInput
        source="level"
        choices={uniqueLevels}
        translateChoice={false}
        optionText="level"
        optionValue="level"
      />
      <SelectInput
        source="category"
        choices={uniqueCategories}
        translateChoice={false}
        optionText="category"
        optionValue="category"
      />
      <SelectInput
        source="name"
        choices={uniqueNames}
        translateChoice={false}
        optionText="name"
        optionValue="name"
      />
      <SelectInput
        source="rank"
        choices={uniqueRanks}
        translateChoice={false}
        optionText="rank"
        optionValue="rank"
      />
    </Filter>
  );
};

const VariablesTreeListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  return (
    <MuiToolbar
      className={className}
      style={{ alignItems: 'center' }}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      <Button
        startIcon={<CloudUpload />}
        color="primary"
        variant="text"
        href="#/variables_tree/import"
      >
        Import
      </Button>
    </MuiToolbar>
  );
};

const useStyles = makeStyles(() => ({
  tableCell: {
    maxWidth: 300,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export const VariablesTreeList = (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      filters={<VariablesTreeFilter />}
      actions={<VariablesTreeListActions />}
      sort={{ field: 'modified', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="path" />
        <TextField source="comp" />
        <TextField source="level" />
        <TextField cellClassName={classes.tableCell} source="category" />
        <TextField cellClassName={classes.tableCell} source="name" />
        <BooleanField source="orphan" />
        <NumberField source="rank" />
        <TextField source="filename" />
        <EditButton />
        <ShowButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const VariablesTreeTitle = ({ record }) => {
  return (
    <span>
      Editing variable tree:{' '}
      <strong>
        {record
          ? `${record.path}${record.comp ? `-> ${record.comp}` : ''}`
          : ''}
      </strong>
    </span>
  );
};

export const VariablesTreeEdit = (props) => (
  <Edit title={<VariablesTreeTitle />} {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source="path" validate={validate} />
      <TextInput source="comp" />
      <NumberInput multiline source="level" validate={validate} />
      <TextInput source="category" validate={validate} />
      <TextInput multiline source="name" />
      <BooleanInput source="orphan" />
      <NumberInput source="rank" validate={validateRank} />
    </SimpleForm>
  </Edit>
);

export const VariablesTreeCreate = (props) => (
  <Create {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source="path" validate={validate} />
      <TextInput source="comp" />
      <NumberInput source="level" validate={validate} />
      <TextInput source="category" validate={validate} />
      <TextInput multiline source="name" />
      <BooleanInput source="orphan" />
      <NumberInput source="rank" validate={validateRank} />
    </SimpleForm>
  </Create>
);

export const VariablesTreeShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="path" />
      <TextField source="comp" />
      <NumberField source="level" />
      <TextField source="category" />
      <TextField source="name" />
      <NumberField source="rank" />
      <BooleanField source="orphan" />
      <TextField source="filename" />
    </SimpleShowLayout>
  </Show>
);
