import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';

export const synchroStateAtom = atom({
  bulkDownload: {
    initiated_at: null,
    finished_at: null,
    status: '',
    error: '',
    elapsed: null,
    average: null,
    averageInMs: null,
    progress_info: '',
  },
  data: {
    initiated_at: null,
    finished_at: null,
    status: '',
    error: '',
    elapsed: null,
    average: null,
    averageInMs: null,
    progress_info: '',
  },
  metadata: {
    initiated_at: null,
    finished_at: null,
    status: '',
    error: '',
    elapsed: null,
    average: null,
    averageInMs: null,
    progress_info: '',
  },
});

export const dataSyncAtom = focusAtom(synchroStateAtom, (optic) =>
  optic.prop('data')
);
export const metadataSyncAtom = focusAtom(synchroStateAtom, (optic) =>
  optic.prop('metadata')
);
export const bulkDownloadAtom = focusAtom(synchroStateAtom, (optic) =>
  optic.prop('bulkDownload')
);
