import React from 'react';
import { Box, Grid } from '@material-ui/core';
import MemoryIcon from '@material-ui/icons/Memory';
import LanguageIcon from '@material-ui/icons/Language';
import TitleIcon from '@material-ui/icons/Title';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

import Welcome from './components/Welcome';
import CardWithIcon from './components/CardWithIcon';

const Dashboard = () => (
  <Box>
    <Welcome />
    <Grid container spacing={3}>
      <Grid item xs={6} md={4}>
        <CardWithIcon to="/wid_data" icon={MemoryIcon} title="WID DATA" />
      </Grid>
      <Grid item xs={6} md={4}>
        <CardWithIcon to="/countries" icon={LanguageIcon} title="Countries" />
      </Grid>
      <Grid item xs={6} md={4}>
        <CardWithIcon
          to="/variables_types"
          icon={TitleIcon}
          title="Variables Types"
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <CardWithIcon
          to="/variables_names"
          icon={AssignmentIcon}
          title="Variables Names"
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <CardWithIcon
          to="/variables_notes"
          icon={MenuBookIcon}
          title="Variables Notes"
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <CardWithIcon
          to="/variables_pops"
          icon={NaturePeopleIcon}
          title="Variables Pops"
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <CardWithIcon
          to="/variables_ages"
          icon={LocalOfferIcon}
          title="Variables Ages"
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <CardWithIcon
          to="/variables_units"
          icon={MonetizationOnIcon}
          title="Variables Units"
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <CardWithIcon
          to="/variables_tree"
          icon={AccountTreeIcon}
          title="Variables Tree"
        />
      </Grid>
    </Grid>
  </Box>
);

export default Dashboard;
