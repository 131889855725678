import React, { cloneElement } from 'react';
import {
  Show,
  ShowButton,
  SimpleShowLayout,
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  SimpleForm,
  TextInput,
  Filter,
  required,
  maxLength,
  CreateButton,
  ExportButton,
  useListContext,
  SelectInput,
  sanitizeListRestProps,
} from 'react-admin';
import { CloudUpload } from '@material-ui/icons';
import { Button, Toolbar as MuiToolbar } from '@material-ui/core';

import useCountries from '../../hooks/useCountries';

const validateAlpha2 = [required(), maxLength(10)];

const CountryFilter = (props) => {
  const { data: countriesList, uniqueRegions, uniqueRegions2 } = useCountries();

  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <SelectInput
        source="alpha2"
        choices={countriesList}
        optionText="alpha2"
        optionValue="alpha2"
      />
      <SelectInput
        source="titlename"
        choices={countriesList}
        translateChoice={false}
        optionText="titlename"
        optionValue="titlename"
      />
      <SelectInput
        source="shortname"
        choices={countriesList}
        translateChoice={false}
        optionText="shortname"
        optionValue="shortname"
      />
      <SelectInput
        source="region"
        choices={uniqueRegions}
        translateChoice={false}
        optionText="region"
        optionValue="region"
      />
      <SelectInput
        source="region2"
        choices={uniqueRegions2}
        translateChoice={false}
        optionText="region2"
        optionValue="region2"
      />
    </Filter>
  );
};

const CountryListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  return (
    <MuiToolbar
      className={className}
      style={{ alignItems: 'center' }}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      <Button
        startIcon={<CloudUpload />}
        color="primary"
        variant="text"
        href="#/countries/import"
      >
        Import
      </Button>
    </MuiToolbar>
  );
};

export const CountryList = (props) => (
  <List {...props} filters={<CountryFilter />} actions={<CountryListActions />}>
    <Datagrid>
      <TextField source="alpha2" />
      <TextField source="titlename" />
      <TextField source="shortname" />
      <TextField source="region" />
      <TextField source="region2" />
      <TextField source="region3" />
      <TextField source="filename" />
      <EditButton />
      <ShowButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

const CountryTitle = ({ record }) => {
  return (
    <span>
      Editing country: {record ? `(${record.alpha2}) ${record.shortname}` : ''}
    </span>
  );
};

export const CountryEdit = (props) => (
  <Edit title={<CountryTitle />} {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source="alpha2" validate={validateAlpha2} />
      <TextInput source="titlename" />
      <TextInput source="shortname" />
      <TextInput source="region" />
      <TextInput source="region2" />
      <TextInput source="region3" />
    </SimpleForm>
  </Edit>
);

export const CountryCreate = (props) => (
  <Create {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source="alpha2" validate={validateAlpha2} />
      <TextInput source="titlename" />
      <TextInput source="shortname" />
      <TextInput source="region" />
      <TextInput source="region2" />
      <TextInput source="region3" />
    </SimpleForm>
  </Create>
);

export const CountryShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="alpha2" />
      <TextField source="titlename" />
      <TextField source="shortname" />
      <TextField source="region" />
      <TextField source="region2" />
      <TextField source="region3" />
      <TextField source="filename" />
    </SimpleShowLayout>
  </Show>
);
