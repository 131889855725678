import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ImageZoom from 'react-medium-image-zoom';
import mainPage from './images/main-page.png';
import tableView from './images/table-view.png';
import editView from './images/edit.png';
import createView from './images/create.png';
import view from './images/view.png';
import importPage from './images/import.png';
import importPreview from './images/import-preview.png';
import dbUpdated from './images/db-updated.png';
import synchro from './images/synchro.png';

const Documentation = () => (
  <div>
    <Typography variant="h4">Documentation</Typography>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Typography variant="h6">1. Dashboard / Sidebar / Topbar</Typography>
        <Typography variant="body1" color="textSecondary">
          This is the first page of the application. From the left side you can
          select the various tables. The main tiles are also another way of
          accessing each table.
          <br />
          <br />
          On the <b>topbar</b>, you can see
          <ol>
            <li>
              the status of the synchronization between <b>DEV</b> and
              <b> PROD</b>. You can click on this button and access the
              Synchronization page of the application.
            </li>
            <li>
              the status of the
              <strong> DB</strong>, when you have already imported a
              <strong>csv</strong> file. The possible statuses are:
              <span style={{ color: 'green' }}> DB updated, </span>
              <span style={{ color: 'red' }}> error updating DB, </span>
              <span style={{ color: 'blue' }}>updating DB</span>.
            </li>
            <li>
              the refresh button to <b>refresh</b> the data of the page
            </li>
            <li>
              the account button to <b>Log out</b> of the app
            </li>
          </ol>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <ImageZoom
          image={{
            src: mainPage,
            alt: 'main-page',
            style: { width: '100%', height: 'auto', border: '1px solid' },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">
          2. Table View / Edit View / Create View
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Here you can create, edit, remove an entry of a table or multiple
          ones. You can import or export to csv. Search or add filters.
          <br />
          <br />
          When clicking on edit you are being navigated to the
          <b> Edit View</b>. From there, you can change values from autocomplete
          fields or normal fields. In the case of the wid_data table you can
          change the values of the JSON object as well.
          <br />
          <br />
          The similar process is with the <b>Edit</b>, <b>Create View</b> and
          <b> Show View</b>.
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ImageZoom
              image={{
                src: tableView,
                alt: 'table-view',
                style: { width: '100%', height: 'auto', border: '1px solid' },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ImageZoom
              image={{
                src: editView,
                alt: 'edit-view',
                style: { width: '100%', height: 'auto', border: '1px solid' },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ImageZoom
              image={{
                src: createView,
                alt: 'create-view',
                style: { width: '100%', height: 'auto', border: '1px solid' },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ImageZoom
              image={{
                src: view,
                alt: 'view',
                style: { width: '100%', height: 'auto', border: '1px solid' },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">3. Import</Typography>
        <Typography variant="body1" color="textSecondary">
          The import procedure is happening on the background and it doesn't
          require the pc to be connected to the internet.
          <br />
          <br />
          The user has to just upload the file to the server and the import is
          being executed there, without blocking the user of doing something
          else.
          <br />
          <br />
          The steps to import a csv file are the following:
          <ol>
            <li>
              Choose a file by clicking or dragging a csv file to the upload
              area.
            </li>
            <li>
              Choose whether to delete all countries first that are related to
              the current file. It may boosts a little bit the update procedure
              of the DB.
            </li>
            <li>
              Choose whether to delete all data from the table first before
              inserting new data.
            </li>
            <li>Check that the required columns exist in the csv file.</li>
            <li>
              Upload the file to the server by pressing the <b>Upload </b>
              button and wait for it to be completed.
            </li>
          </ol>
          You can then resume your work and the database will be updated in the
          background. When it is finished then the
          <span style={{ color: 'green' }}> DB updated</span> status will be
          shown in the Topbar with the duration of the process. Otherwise an
          error will be shown if there is one.
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ImageZoom
              image={{
                src: importPage,
                alt: 'import-page',
                style: { width: '100%', height: 'auto', border: '1px solid' },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ImageZoom
              image={{
                src: importPreview,
                alt: 'import-preview',
                style: { width: '100%', height: 'auto', border: '1px solid' },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ImageZoom
              image={{
                src: dbUpdated,
                alt: 'db-updated',
                style: { width: '100%', height: 'auto', border: '1px solid' },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">4. Sync</Typography>
        <Typography variant="body1" color="textSecondary">
          The synchronization process is also happening in the background as
          long as you press the Sync button. You can click on the synchro button
          of the topbar and then select either:
          <ol>
            <li>Synchronize Data</li>
            <li>Synchronize Metadata</li>
          </ol>
          When the synchronization is completed, the status will be shown in the
          topbar and the last sync date/time will be shown in the
          synchronization page along with the status message of the process.
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <ImageZoom
          image={{
            src: synchro,
            alt: 'synchro',
            style: { width: '100%', height: 'auto', border: '1px solid' },
          }}
        />
      </Grid>
    </Grid>
  </div>
);
export default Documentation;
