import React, { useContext, useEffect, useState } from 'react';
import { AppBar, useNotify } from 'react-admin';
import { useQuery } from 'react-query';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Tooltip } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { SyncAltSharp } from '@material-ui/icons';

import { AppContext } from '../Context/AppContext';
import request from '../../utils/request';
import CustomUserMenu from '../CustomUserMenu';
import { SSyncIcon, SErrorIcon } from '../../styles';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

const CustomAppBar = (props) => {
  const classes = useStyles();
  const [startImport, setStartImport] = useState(false);
  const [startSync, setStartSync] = useState(false);
  const [state, setState] = useContext(AppContext);
  const { dbStatus, trigger, dbErrorDetails, importDuration } = state;

  const syncStatusLoading =
    state?.data?.status === 'loading' || state?.metadata?.status === 'loading';
  const syncStatusSuccess =
    state?.data?.status === 'success' && state?.metadata?.status === 'success';
  const syncStatusError =
    state?.data?.status === 'error' || state?.metadata?.status === 'error';

  const notify = useNotify();

  useQuery('importStatus', () => request.get('/api/import/status'), {
    refetchInterval: startImport ? 5000 : false,
    onSuccess: (res) => {
      setState({
        ...state,
        dbStatus: res?.data?.status,
        dbErrorDetails: res?.data?.detail,
        trigger: res?.data?.status === 'loading',
        importDuration: res?.data?.duration,
      });
    },
    onError: (error) => {
      setState({
        ...state,
        dbStatus: 'error',
        dbErrorDetails: 'Something went wrong in the API',
        trigger: false,
      });
    },
  });

  useQuery('synchroStatus', () => request.get('/api/synchro/status'), {
    refetchInterval: startSync ? 5000 : false,
    onSuccess: (res) => {
      setState({
        ...state,
        ...res?.data,
      });
    },
    onError: (error) => {
      notify(error, 'warning');
    },
  });

  useEffect(() => {
    if (trigger || dbStatus === 'loading') {
      setStartImport(true);
    } else {
      setStartImport(false);
    }
  }, [trigger]);

  useEffect(() => {
    if (syncStatusLoading) {
      setStartSync(true);
    } else {
      setStartSync(false);
    }
  }, [syncStatusLoading]);

  return (
    <AppBar {...props} color="primary" userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Button href="/" color="inherit" style={{ fontSize: '1.5rem' }}>
        WID ADMIN
      </Button>
      <span className={classes.spacer} />
      <Button
        startIcon={
          syncStatusLoading ? (
            <SSyncIcon />
          ) : syncStatusSuccess ? (
            <CheckBoxIcon />
          ) : (
            <SyncAltSharp />
          )
        }
        style={
          syncStatusSuccess
            ? { color: 'limegreen' }
            : syncStatusLoading
            ? { color: 'aqua' }
            : syncStatusError
            ? { color: 'red' }
            : { color: 'white' }
        }
        variant="text"
        href="#/synchro"
      >
        Synchro
      </Button>
      {dbStatus === 'loading' ? (
        <Box color="info.main" display="flex" alignItems="center">
          <SSyncIcon />
          <Typography variant="caption">updating DB...</Typography>
        </Box>
      ) : dbStatus === 'loaded' ? (
        <Tooltip
          title={`Duration: ${importDuration} min`}
          aria-label="Duration"
        >
          <Box color="success.main" display="flex" alignItems="center">
            <CheckBoxIcon />
            <Typography variant="caption">DB updated</Typography>
          </Box>
        </Tooltip>
      ) : dbStatus === 'error' ? (
        <Tooltip title={dbErrorDetails} aria-label={dbErrorDetails}>
          <Box color="error.main" display="flex" alignItems="center">
            <SErrorIcon />
            <Typography variant="caption">error updating DB</Typography>
          </Box>
        </Tooltip>
      ) : null}
    </AppBar>
  );
};

export default CustomAppBar;
