import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import {
  Typography,
  CardContent,
  Card,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Divider,
  Tooltip,
  CardActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useMutation, useQuery } from 'react-query';
import dayjs from 'dayjs';
import Countdown from 'react-countdown';
import { useAtom } from 'jotai';

import request from '../../../utils/request';
import { SSyncIcon } from '../../../styles';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  cardContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'initial',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  button: {
    marginRight: 20,
  },
  lastSync: {
    marginLeft: 10,
  },
}));

const API_URL = `${
  process.env.REACT_APP_API_URL || 'http://localhost:5000'
}/api`;

const SynchronizeCard = ({
  type,
  cardTitle,
  user,
  stateAtom,
  withConfirmDialog = false,
}) => {
  const classes = useStyles();
  const notify = useNotify();

  const [open, setOpen] = useState(false);
  const [intervalEnabled, setIntervalEnabled] = useState(false);
  const [currentState, setCurrentState] = useAtom(stateAtom);

  const initiatedAtDate = dayjs(currentState?.initiated_at);
  const toBeFinishedAt = initiatedAtDate.add(
    Number(currentState?.average) + 1,
    'minute'
  );

  const cardMessage =
    type === 'bulkDownload'
      ? 'Generate of bulk data is currently running...'
      : `${type} synchronization is running...`;
  const STATUS_URL =
    type === 'bulkDownload'
      ? '/api/download-bulk-data/status'
      : '/api/synchro/status';
  const INIT_PROCESS_URL =
    type === 'bulkDownload'
      ? '/api/download-bulk-data/init'
      : `/api/synchro/${type}`;

  useEffect(() => {
    // add an extra 1 min in average
    !currentState.averageInMs &&
      currentState?.average &&
      setCurrentState((prev) => ({
        ...prev,
        averageInMs:
          new Date(currentState?.initiated_at).getTime() +
          (Number(currentState?.average) + 1) * 60000,
      }));
  }, [
    currentState?.average,
    currentState.averageInMs,
    currentState?.initiated_at,
  ]);

  useEffect(() => {
    currentState?.status === 'loading' &&
      !intervalEnabled &&
      setIntervalEnabled(true);
  }, [currentState?.status, intervalEnabled]);

  useQuery(
    `${type === 'bulkDownload' ? 'bulkDownload' : 'synchro'}Status`,
    () => request.get(STATUS_URL),
    {
      refetchInterval: intervalEnabled ? 5000 : false,
      onSuccess: (res) => {
        setCurrentState((prev) => ({
          ...prev,
          ...(type === 'bulkDownload' ? res.data : res.data?.[type]),
        }));
        res.data?.status !== 'loading' && setIntervalEnabled(false);
      },
      onError: (error) => {
        setCurrentState((prev) => ({
          ...prev,
          error: `Something went wrong. ${JSON.stringify(error)}`,
        }));
        notify(`something went wrong while fetching ${type} status`, 'error');
        console.error(error);
        setIntervalEnabled(false);
      },
    }
  );

  const processInit = useMutation(
    `${type}ProcessInit`,
    (data) => request.post(INIT_PROCESS_URL, data),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setIntervalEnabled(true);
        }
      },
      onError: () => {
        notify(`error while initiating ${type} process`, 'error');
      },
    }
  );

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const confirmSync = () => {
    processInit.mutate({ user: user?.fullName });
    handleCloseDialog();
  };

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Typography variant="h5" component="h5">
          <Box display="flex" alignItems="center" gridGap="8px">
            <span>{cardTitle}</span>
            {currentState?.status === 'error' && (
              <Tooltip title={currentState?.error || ''}>
                <ErrorOutlineIcon color="error" />
              </Tooltip>
            )}
          </Box>
        </Typography>
        {currentState?.status === 'loading' && (
          <Box mb="32px">
            <Box
              my="16px"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gridGap="8px"
            >
              <SSyncIcon />
              <Typography style={{ color: 'darkgreen' }} variant="body1">
                {cardMessage}
              </Typography>
            </Box>
            <Countdown
              key={`${type}-countdown`}
              date={toBeFinishedAt.toDate()}
              renderer={({ hours, completed, formatted }) => {
                if (completed) {
                  // Render a completed state
                  return <span>Almost finished....</span>;
                } else {
                  // Render a countdown
                  return (
                    <span>
                      {hours ? `${hours}:` : ''}
                      {formatted.minutes}:{formatted.seconds}{' '}
                      {hours ? `hours` : `min`} left
                    </span>
                  );
                }
              }}
            />
            {currentState?.progress_info?.length ? (
              <Box display="flex" alignItems="center" gridGap="8px">
                <Typography variant="body2">Progress info:</Typography>
                {currentState?.progress_info?.split(',').map((info, index) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gridGap="8px"
                    key={index}
                  >
                    <Typography variant="caption">
                      {index < currentState?.progress_info.length - 1
                        ? `${info}...`
                        : `${info} ✅`}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : null}
          </Box>
        )}

        <Typography style={{ marginTop: '12px' }} variant="subtitle1">
          Last execution results:
        </Typography>
        <Divider style={{ width: '30%', marginBottom: '12px' }} />

        <Typography variant="body1" component="div">
          Average time:
          <Typography
            variant="body1"
            component="span"
            color="primary"
            className={classes.lastSync}
          >
            {currentState?.average ? `${currentState?.average} min` : '-'}
          </Typography>
        </Typography>

        <Typography variant="body1" component="div">
          Status:
          <Typography
            variant="body1"
            component="span"
            style={{
              color:
                currentState?.status === 'success'
                  ? 'green'
                  : currentState?.status === 'error'
                  ? 'red'
                  : 'black',
            }}
            className={classes.lastSync}
          >
            {currentState?.status ?? '-'}
          </Typography>
        </Typography>

        <Typography variant="body1" component="div">
          Initiated at:
          <Typography
            variant="body1"
            component="span"
            color="primary"
            className={classes.lastSync}
          >
            {currentState?.initiated_at
              ? dayjs(currentState?.initiated_at).format('MMMM D, YYYY h:mm a')
              : '-'}
          </Typography>
        </Typography>

        <Typography variant="body1" component="div">
          Finished at:
          <Typography
            variant="body1"
            component="span"
            color="primary"
            className={classes.lastSync}
          >
            {currentState?.finished_at
              ? dayjs(currentState?.finished_at).format('MMMM D, YYYY h:mm a')
              : '...'}
          </Typography>
        </Typography>

        <Typography variant="body1" component="div">
          Elapsed:
          <Typography
            variant="body1"
            component="span"
            color="primary"
            className={classes.lastSync}
          >
            {currentState?.elapsed ? `${currentState?.elapsed} min` : '...'}
          </Typography>
        </Typography>
      </CardContent>
      <CardActions style={{ padding: '16px' }}>
        <Box display="flex" alignItems="center">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() =>
              type === 'bulkDownload'
                ? processInit.mutate({ user: user?.fullName })
                : handleOpenDialog()
            }
            startIcon={
              currentState?.status === 'loading' || intervalEnabled ? (
                <SSyncIcon />
              ) : null
            }
            disabled={currentState?.status === 'loading' || intervalEnabled}
          >
            {type === 'bulkDownload'
              ? 'Generate bulk data'
              : `Synchronize ${type}`}
          </Button>
          {type === 'bulkDownload' && (
            <Button
              className={classes.button}
              variant="contained"
              color="default"
              href={`${API_URL}/download-bulk-data/download`}
            >
              Download zip (all data)
            </Button>
          )}
        </Box>
      </CardActions>
      {withConfirmDialog && (
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Synchronize Confirmation
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action will synchronize <strong>{type}</strong> between DEV
              -> PROD. Are you sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              No
            </Button>
            <Button onClick={confirmSync} color="primary" autoFocus>
              YES
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Card>
  );
};

export default SynchronizeCard;
