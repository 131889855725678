import React from 'react';
import { Title, useGetIdentity } from 'react-admin';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SynchronizeCard from './components';
import { bulkDownloadAtom, dataSyncAtom, metadataSyncAtom } from '../../atoms';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '24px 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const Synchronize = () => {
  const { identity } = useGetIdentity();

  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      display="flex"
      flexDirection="row"
      gridGap="8px"
      alignItems="center"
      width="100%"
    >
      <Title title="Synchro - Bulk download" />
      <SynchronizeCard
        type="data"
        cardTitle="Data Synchronization"
        user={identity}
        stateAtom={dataSyncAtom}
        withConfirmDialog
      />
      <SynchronizeCard
        type="metadata"
        cardTitle="Metadata Synchronization"
        user={identity}
        stateAtom={metadataSyncAtom}
        withConfirmDialog
      />
      <SynchronizeCard
        type="bulkDownload"
        cardTitle="Bulk Download - FTP"
        user={identity}
        stateAtom={bulkDownloadAtom}
      />
    </Box>
  );
};

export default Synchronize;
