import { useGetList } from 'react-admin';

const useCountries = () => {
  const { data, ...rest } = useGetList(
    'countries',
    {
      page: 1,
      perPage: 400,
    },
    { field: 'alpha2', order: 'ASC' }
  );

  const countriesList = Object.values(data);

  const uniqueRegions = countriesList.reduce(
    (acc, x) => acc.concat(acc.find((y) => y.region === x.region) ? [] : [x]),
    []
  );

  const uniqueRegions2 = countriesList.reduce(
    (acc, x) => acc.concat(acc.find((y) => y.region2 === x.region2) ? [] : [x]),
    []
  );

  return {
    ...rest,
    data: countriesList,
    uniqueRegions,
    uniqueRegions2,
  };
};

export default useCountries;
