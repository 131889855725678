const getHeaderNames = (resource) => {
  switch (resource) {
    case 'WID Data':
      return ['alpha2', 'year', 'perc', 'widcode', 'value'];
    case 'Variables Ages':
      return ['agecode', 'shortname', 'fullname', 'rank'];
    case 'Variables Types':
      return [
        'onetype',
        'shortdes',
        'longdes',
        'onlinedes',
        'onlinedes2',
        'rank',
      ];
    case 'Variables Pops':
      return ['onepop', 'shortdes', 'longdes', 'rank'];
    case 'Variables Units':
      return ['country', 'var_type', 'metadata'];
    case 'Variables Tree':
      return ['path', 'comp', 'level', 'category', 'name', 'orphan', 'rank'];
    case 'Variables Names':
      return [
        'twolet',
        'threelet',
        'shortname',
        'simpledes',
        'technicaldes',
        'rank',
      ];
    case 'Variables Notes':
      return [
        'alpha2',
        'twolet',
        'threelet',
        'method',
        'source',
        'data_quality',
        'imputation',
        'extrapolation',
        'data_points',
      ];
    case 'Countries':
      return [
        'alpha2',
        'titlename',
        'shortname',
        'region',
        'region2',
        'region3',
      ];
    default:
      return [];
  }
};

const getResourceTitle = (resource) => {
  switch (resource) {
    case 'wid_data':
      return 'WID Data';
    case 'variables_ages':
      return 'Variables Ages';
    case 'variables_types':
      return 'Variables Types';
    case 'variables_pops':
      return 'Variables Pops';
    case 'variables_units':
      return 'Variables Units';
    case 'variables_tree':
      return 'Variables Tree';
    case 'variables_names':
      return 'Variables Names';
    case 'variables_notes':
      return 'Variables Notes';
    case 'countries':
      return 'Countries';
    default:
      return [];
  }
};

const formatBytes = (bytes, decimals = 1) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const number = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, number)).toFixed(dm))} ${
    sizes[number]
  }`;
};

export { getHeaderNames, getResourceTitle, formatBytes };
