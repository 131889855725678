import { useGetList } from 'react-admin';

const useTypes = () => {
  const { data, ...rest } = useGetList(
    'variables_types',
    {
      page: 1,
      perPage: 100,
    },
    { field: 'onetype', order: 'ASC' }
  );

  return { ...rest, data: Object.values(data) };
};

export default useTypes;
