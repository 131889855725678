import React, { cloneElement } from 'react';
import {
  Show,
  ShowButton,
  SimpleShowLayout,
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  SimpleForm,
  TextInput,
  Filter,
  required,
  maxLength,
  CreateButton,
  ExportButton,
  useListContext,
  NumberInput,
  NumberField,
  number,
  minLength,
  sanitizeListRestProps,
  SelectInput,
} from 'react-admin';
import { CloudUpload } from '@material-ui/icons';
import { Button, Toolbar as MuiToolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useNames from '../../hooks/useNames';

const validateTwoLet = [required(), minLength(2), maxLength(2)];
const validateThreeLet = [required(), minLength(3), maxLength(3)];
const validateRank = [number()];

const VariablesNamesFilter = (props) => {
  const {
    data: { uniqueShortnames, uniqueVar, uniqueY, uniqueRanks },
  } = useNames();

  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <SelectInput
        source="twolet"
        choices={uniqueY}
        optionText="twolet"
        optionValue="twolet"
      />
      <SelectInput
        source="threelet"
        choices={uniqueVar}
        translateChoice={false}
        optionText="threelet"
        optionValue="threelet"
      />
      <SelectInput
        source="shortname"
        choices={uniqueShortnames}
        translateChoice={false}
        optionText="shortname"
        optionValue="shortname"
      />
      <SelectInput
        source="rank"
        choices={uniqueRanks}
        translateChoice={false}
        optionText="rank"
        optionValue="rank"
      />
    </Filter>
  );
};

const VariablesNamesListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  return (
    <MuiToolbar
      className={className}
      style={{ alignItems: 'center' }}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      <Button
        startIcon={<CloudUpload />}
        color="primary"
        variant="text"
        href="#/variables_names/import"
      >
        Import
      </Button>
    </MuiToolbar>
  );
};

const useStyles = makeStyles(() => ({
  tableCell: {
    maxWidth: 300,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export const VariablesNamesList = (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      filters={<VariablesNamesFilter />}
      actions={<VariablesNamesListActions />}
    >
      <Datagrid>
        <TextField source="twolet" />
        <TextField source="threelet" />
        <TextField cellClassName={classes.tableCell} source="shortname" />
        <TextField cellClassName={classes.tableCell} source="simpledes" />
        <TextField cellClassName={classes.tableCell} source="technicaldes" />
        <NumberField source="rank" />
        <TextField source="filename" />
        <EditButton />
        <ShowButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const VariablesNamesTitle = ({ record }) => {
  return (
    <span>
      Editing variable name:{' '}
      <strong>{record ? `${record.twolet} ${record.threelet}` : ''}</strong>
    </span>
  );
};

export const VariablesNamesEdit = (props) => (
  <Edit title={<VariablesNamesTitle />} {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source="twolet" validate={validateTwoLet} />
      <TextInput source="threelet" validate={validateThreeLet} />
      <TextInput multiline source="shortname" />
      <TextInput multiline source="simpledes" />
      <TextInput multiline source="technicaldes" />
      <NumberInput source="rank" validate={validateRank} />
    </SimpleForm>
  </Edit>
);

export const VariablesNamesCreate = (props) => (
  <Create {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source="twolet" validate={validateTwoLet} />
      <TextInput source="threelet" validate={validateThreeLet} />
      <TextInput multiline source="shortname" />
      <TextInput multiline source="simpledes" />
      <TextInput multiline source="technicaldes" />
      <NumberInput source="rank" validate={validateRank} />
    </SimpleForm>
  </Create>
);

export const VariablesNamesShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="twolet" />
      <TextField source="threelet" />
      <TextField source="shortname" />
      <TextField source="simpledes" />
      <TextField source="technicaldes" />
      <NumberField source="rank" />
      <TextField source="filename" />
    </SimpleShowLayout>
  </Show>
);
