import * as React from 'react';
import { Route } from 'react-router-dom';
import ImportData from './scenes/ImportData';
import Synchronize from './scenes/Synchronize';
import Documentation from './scenes/Documentation';

const routes = [
  <Route exact path="/:resource/import" component={ImportData} />,
  <Route exact path="/synchro" component={Synchronize} />,
  <Route exact path="/documentation" component={Documentation} />,
];

export default routes;
