import { useGetList } from 'react-admin';

const useNotes = () => {
  const { data, ...rest } = useGetList(
    'variables_notes',
    {
      page: 1,
      perPage: 2000,
    },
    { field: 'alpha2', order: 'ASC' }
  );

  const notesList = Object.values(data);

  const uniqueTwoLet = notesList.reduce(
    (acc, x) => acc.concat(acc.find((y) => y.twolet === x.twolet) ? [] : [x]),
    []
  );

  const uniqueThreeLet = notesList.reduce(
    (acc, x) =>
      acc.concat(acc.find((y) => y.threelet === x.threelet) ? [] : [x]),
    []
  );

  const uniqueDataQuality = notesList.reduce(
    (acc, x) =>
      acc.concat(acc.find((y) => y.data_quality === x.data_quality) ? [] : [x]),
    []
  );

  const uniqueImputation = notesList.reduce(
    (acc, x) =>
      acc.concat(acc.find((y) => y.imputation === x.imputation) ? [] : [x]),
    []
  );

  const uniqueExtrapolation = notesList.reduce(
    (acc, x) =>
      acc.concat(
        acc.find((y) => y.extrapolation === x.extrapolation) ? [] : [x]
      ),
    []
  );

  const uniqueDatapoints = notesList.reduce(
    (acc, x) =>
      acc.concat(acc.find((y) => y.data_points === x.data_points) ? [] : [x]),
    []
  );

  return {
    ...rest,
    data: {
      notesList,
      uniqueTwoLet,
      uniqueThreeLet,
      uniqueDataQuality,
      uniqueImputation,
      uniqueExtrapolation,
      uniqueDatapoints,
    },
  };
};

export default useNotes;
