import styled, {keyframes} from "styled-components";
import CachedIcon from "@material-ui/icons/Cached";
import {ErrorOutline} from "@material-ui/icons";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const blink = keyframes`
  from {
    opacity: 50%;
  }

  to {
    opacity: 100%;
  }
`;

export const SSyncIcon = styled(CachedIcon)`
  animation: ${rotate} 2s linear infinite;
`;

export const SErrorIcon = styled(ErrorOutline)`
  animation: ${blink} 1s ease infinite;
`;
