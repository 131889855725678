import { defaultTheme } from 'react-admin';
import { merge } from 'lodash';
import red from '@material-ui/core/colors/red';

const customTheme = merge({}, defaultTheme, {
  palette: {
    primary: { main: '#1c6179' },
    secondary: { main: '#FF7664' },
    error: red,
  },
});

export default customTheme;
