import React, { cloneElement } from 'react';
import {
  Show,
  ShowButton,
  SimpleShowLayout,
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  SimpleForm,
  TextInput,
  Filter,
  required,
  CreateButton,
  ExportButton,
  useListContext,
  NumberInput,
  NumberField,
  number,
  SelectInput,
  sanitizeListRestProps,
} from 'react-admin';
import { CloudUpload } from '@material-ui/icons';
import { Button, Toolbar as MuiToolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useAges from '../../hooks/useAges';

const validateAge = [required()];
const validateRank = [number()];

const VariablesAgesFilter = (props) => {
  const { data: agesList, uniqueRanks } = useAges();

  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <SelectInput
        source="agecode"
        choices={agesList}
        optionText="agecode"
        optionValue="agecode"
      />
      <SelectInput
        source="shortname"
        choices={agesList}
        translateChoice={false}
        optionText="shortname"
        optionValue="shortname"
      />
      <SelectInput
        source="rank"
        choices={uniqueRanks}
        translateChoice={false}
        optionText="rank"
        optionValue="rank"
      />
    </Filter>
  );
};

const VariablesAgesListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  return (
    <MuiToolbar
      className={className}
      style={{ alignItems: 'center' }}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      <Button
        startIcon={<CloudUpload />}
        color="primary"
        variant="text"
        href="#/variables_ages/import"
      >
        Import
      </Button>
    </MuiToolbar>
  );
};

const useStyles = makeStyles(() => ({
  tableCell: {
    maxWidth: 300,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export const VariablesAgesList = (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      filters={<VariablesAgesFilter />}
      actions={<VariablesAgesListActions />}
    >
      <Datagrid>
        <TextField source="agecode" />
        <TextField source="shortname" />
        <TextField cellClassName={classes.tableCell} source="fullname" />
        <NumberField source="rank" />
        <TextField source="filename" />
        <EditButton />
        <ShowButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const VariablesAgesTitle = ({ record }) => {
  return (
    <span>
      Editing variable age:{' '}
      <strong>{record ? `(${record.agecode})${record.shortname}` : ''}</strong>
    </span>
  );
};

export const VariablesAgesEdit = (props) => (
  <Edit title={<VariablesAgesTitle />} {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source="agecode" validate={validateAge} />
      <TextInput multiline source="shortname" />
      <TextInput multiline source="fullname" />
      <NumberInput source="rank" validate={validateRank} />
    </SimpleForm>
  </Edit>
);

export const VariablesAgesCreate = (props) => (
  <Create {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source="agecode" validate={validateAge} />
      <TextInput multiline source="shortname" />
      <TextInput multiline source="fullname" />
      <NumberInput source="rank" validate={validateRank} />
    </SimpleForm>
  </Create>
);

export const VariablesAgesShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="agecode" />
      <TextField source="shortname" />
      <TextField source="fullname" />
      <NumberField source="rank" />
      <TextField source="filename" />
    </SimpleShowLayout>
  </Show>
);
