import { useGetList } from 'react-admin';

const useNames = () => {
  const { data, ...rest } = useGetList(
    'variables_names',
    {
      page: 1,
      perPage: 2000,
    },
    { field: 'twolet', order: 'ASC' }
  );

  const namesList = Object.values(data);
  const uniqueY = namesList.reduce(
    (acc, x) => acc.concat(acc.find((y) => y.twolet === x.twolet) ? [] : [x]),
    []
  );
  const uniqueVar = namesList.reduce(
    (acc, x) =>
      acc.concat(acc.find((y) => y.threelet === x.threelet) ? [] : [x]),
    []
  );

  const uniqueRanks = Object.values(data).reduce(
    (acc, x) => acc.concat(acc.find((y) => y.rank === x.rank) ? [] : [x]),
    []
  );

  const uniqueShortnames = Object.values(data).reduce(
    (acc, x) =>
      acc.concat(acc.find((y) => y.shortname === x.shortname) ? [] : [x]),
    []
  );

  return {
    ...rest,
    data: { uniqueShortnames, uniqueY, uniqueVar, uniqueRanks },
  };
};

export default useNames;
