import React, { useState, createContext } from 'react';

// Create Context Object
export const AppContext = createContext({});

// Create a provider for components to consume and subscribe to changes
export const AppContextProvider = ({ children }) => {
  const [value, setValue] = useState({
    trigger: false,
    dbStatus: 'idle',
    dbErrorDetails: null,
    importDuration: null,
  });

  return (
    <AppContext.Provider value={[value, setValue]}>
      {children}
    </AppContext.Provider>
  );
};
