import React, { useContext, useEffect, useState } from 'react';
import { Title, useNotify } from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  Typography,
  CardContent,
  Card,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  TableHead,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DeleteIcon from '@material-ui/icons/Delete';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import {
  formatBytes,
  getHeaderNames,
  getResourceTitle,
} from '../../utils/helpers';

import awsExports from '../../aws-exports';
import { Amplify } from '@aws-amplify/core';
import { Storage } from 'aws-amplify';
import axios from 'axios';
import { AppContext } from '../../components/Context/AppContext';
Amplify.configure({
  Auth: awsExports,
  Storage: {
    AWSS3: {
      bucket: 'wid-admin-uploads',
      region: 'eu-west-1',
    },
  },
});

registerPlugin(FilePondPluginFileValidateType);

const useStyles = makeStyles({
  tableActions: {
    display: 'flex',
    gap: '1rem',
  },
  table: {
    minWidth: 650,
  },
  container: {
    marginBottom: 30,
    marginTop: 30,
  },
  button: {
    marginRight: 20,
  },
  headerNames: {
    marginLeft: 10,
  },
});

const ImportData = () => {
  const { resource } = useParams();
  const resourceTitle = getResourceTitle(resource);
  const headerNames = getHeaderNames(resourceTitle);
  const classes = useStyles();
  const notify = useNotify();
  const [state, setState] = useContext(AppContext);
  const { dbStatus } = state;
  const [activeStep, setActiveStep] = useState(false);
  const [files, setFiles] = useState([]);
  const showDeleteCountryChoice = [
    'WID Data',
    'Variables Units',
    'Variables Notes',
    'Countries',
  ].includes(resourceTitle);
  const [deleteOption, setDeleteOption] = React.useState('');

  const handleChange = (event) => {
    setDeleteOption(event.target.value);
  };

  const startUpdate = (fileKey) => {
    setState({ ...state, trigger: true });
    axios
      .post(`api/import/${resource}`, {
        fileKey: fileKey,
        deleteOption,
      })
      .then((dt) => {
        notify(
          'Updating of database has just started! Wait until the DB is ready.',
          'info',
          {},
          false,
          5000
        );
      })
      .catch((e) => {
        notify(
          'An error occurred while trying to update the DB. ' + e.message,
          'error',
          {},
          false,
          5000
        );
      });
  };

  const removeFile = (fileKey, cb = undefined, cbError = undefined) => {
    Storage.remove(fileKey)
      .then(() => {
        fetchFiles();
        notify('File removed successfully!', 'success', {}, false, 5000);
        cb && cb();
      })
      .catch((e) => {
        cbError && cbError(e);
        notify(
          `Error while deleting file! ${e.message()}`,
          'warning',
          {},
          false,
          5000
        );
      });
  };

  const fetchFiles = () => {
    Storage.list('')
      .then((resp) => {
        setFiles(resp);
      })
      .catch((e) => {
        notify('Error while fetching files!', 'warning', {}, false, 5000);
      });
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <Card>
      <Title title={`Import ${resourceTitle}`} />
      <CardContent>
        <Stepper orientation="vertical">
          <Step active>
            <StepLabel>Select update option</StepLabel>
            <StepContent>
              <Typography>
                Select either <br />
                1. <b>"Update only"</b>, <br />
                2. <b>"Delete all rows for country"</b> if you want to delete
                data for the countries that exist in the csv, or <br />
                3. <b>"Bulk insert-DELETE ALL"</b>, if you want to delete{' '}
                <strong>ALL </strong>
                the data that exist on the table first and the upload.{' '}
                <strong>WITH CAUTION!</strong>
              </Typography>
              <RadioGroup
                row
                name="delete-choice"
                defaultValue={''}
                value={deleteOption}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={''}
                  control={<Radio color="primary" />}
                  label="Update only"
                  labelPlacement="end"
                />
                {showDeleteCountryChoice && (
                  <FormControlLabel
                    value={'deleteCountry'}
                    control={<Radio color="primary" />}
                    label="Delete all rows for country"
                    labelPlacement="end"
                  />
                )}
                <FormControlLabel
                  value={'bulkInsertData'}
                  control={<Radio color="primary" />}
                  label="Bulk insert-DELETE ALL"
                  labelPlacement="end"
                />
              </RadioGroup>
            </StepContent>
          </Step>
          <Step active>
            <StepLabel>Upload the .csv file</StepLabel>
            <StepContent>
              <Typography>
                Either drag 'n drop the file or click in the panel to browse for
                file. <br />
                Please wait for the upload to finish before exiting the page.
                <br />
                As soon as the file upload is finished, the data upload will
                initiate in the server.
              </Typography>
              <Typography variant="caption" component="div">
                The csv should have the following format and headers:
                <Typography
                  className={classes.headerNames}
                  variant="body1"
                  component="span"
                  color="primary"
                >
                  {headerNames.join(' | ')}
                </Typography>
              </Typography>
              <div>
                <FilePond
                  chunkUploads
                  credits={false}
                  acceptedFileTypes={['text/csv']}
                  allowMultiple={false}
                  maxFiles={1}
                  name="file"
                  labelIdle='Drop a .csv file to upload, or <span className="filepond--label-action">Browse</span> to select one'
                  server={{
                    revert: (uniqueFileId, load, error) => {
                      removeFile(uniqueFileId, load, error);
                    },
                    process: (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort,
                      transfer,
                      options
                    ) => {
                      try {
                        Storage.put(file.name, file, {
                          progressCallback({ loaded, total }) {
                            progress(true, loaded, total);
                          },
                        }).then((data) => {
                          load(data.key);
                          fetchFiles();
                          startUpdate(data.key);
                        });
                      } catch (e) {
                        error(e.message());
                      }

                      return {
                        abort: () => {
                          abort();
                        },
                      };
                    },
                  }}
                />
              </div>
            </StepContent>
          </Step>
          <Step active={activeStep}>
            <StepLabel
              onClick={() => setActiveStep(!activeStep)}
              style={{ cursor: 'pointer' }}
              StepIconComponent={InfoOutlinedIcon}
            >
              Or...Select an already uploaded file from the list
            </StepLabel>
            <StepContent>
              <Typography>
                Select a file below that has been already uploaded. <br />
                Select <b>UPDATE</b> to update the DB with this file, or{' '}
                <b>DELETE</b> to delete it from folder.
              </Typography>
              <Paper>
                <TableContainer>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">#</TableCell>
                        <TableCell align="left">File name</TableCell>
                        <TableCell align="left">File size</TableCell>
                        <TableCell align="left">Date Uploaded</TableCell>
                        <TableCell align="left">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {files.length ? (
                        files.map((file, index) => (
                          <TableRow key={file.key}>
                            <TableCell align="left">{index + 1}</TableCell>
                            <TableCell align="left">{file.key}</TableCell>
                            <TableCell align="left">
                              {formatBytes(file.size)}
                            </TableCell>
                            <TableCell align="left">
                              {file.lastModified.toLocaleString()}
                            </TableCell>
                            <TableCell
                              className={classes.tableActions}
                              align="left"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => startUpdate(file.key)}
                                disabled={dbStatus === 'loading'}
                              >
                                Update DB
                              </Button>
                              <Button
                                size="small"
                                endIcon={<DeleteIcon />}
                                variant="contained"
                                color="secondary"
                                onClick={() => removeFile(file.key)}
                                disabled={dbStatus === 'loading'}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Typography variant="h6" align="center">
                          Empty file list
                        </Typography>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </StepContent>
          </Step>
        </Stepper>
      </CardContent>
    </Card>
  );
};

export default ImportData;
