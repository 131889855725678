import { useGetList } from 'react-admin';

const useAges = () => {
  const { data, ...rest } = useGetList(
    'variables_ages',
    {
      page: 1,
      perPage: 300,
    },
    { field: 'agecode', order: 'ASC' }
  );

  const uniqueRanks = Object.values(data).reduce(
    (acc, x) => acc.concat(acc.find((y) => y.rank === x.rank) ? [] : [x]),
    []
  );

  return { ...rest, data: Object.values(data), uniqueRanks };
};

export default useAges;
