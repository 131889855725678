import React, { cloneElement } from 'react';
import {
  Show,
  ShowButton,
  SimpleShowLayout,
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  SimpleForm,
  TextInput,
  Filter,
  CreateButton,
  ExportButton,
  useListContext,
  AutocompleteInput,
  SelectInput,
  sanitizeListRestProps,
} from 'react-admin';
import { CloudUpload } from '@material-ui/icons';
import { Button, Toolbar as MuiToolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useNotes from '../../hooks/useNotes';
import useCountries from '../../hooks/useCountries';
import useNames from '../../hooks/useNames';

const VariablesNotesFilter = (props) => {
  const { data: countriesList } = useCountries();
  const {
    data: {
      uniqueTwoLet,
      uniqueThreeLet,
      uniqueDataQuality,
      uniqueImputation,
      uniqueExtrapolation,
      uniqueDatapoints,
    },
  } = useNotes();

  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <SelectInput
        source="alpha2"
        choices={countriesList}
        optionText="alpha2"
        optionValue="alpha2"
      />
      <SelectInput
        source="twolet"
        choices={uniqueTwoLet}
        translateChoice={false}
        optionText="twolet"
        optionValue="twolet"
      />
      <SelectInput
        source="threelet"
        choices={uniqueThreeLet}
        translateChoice={false}
        optionText="threelet"
        optionValue="threelet"
      />
      <SelectInput
        source="data_quality"
        choices={uniqueDataQuality}
        translateChoice={false}
        optionText="data_quality"
        optionValue="data_quality"
      />
      <SelectInput
        source="imputation"
        choices={uniqueImputation}
        translateChoice={false}
        optionText="imputation"
        optionValue="imputation"
      />
      <SelectInput
        source="extrapolation"
        choices={uniqueExtrapolation}
        translateChoice={false}
        optionText="extrapolation"
        optionValue="extrapolation"
      />
      <SelectInput
        source="data_points"
        choices={uniqueDatapoints}
        translateChoice={false}
        optionText="data_points"
        optionValue="data_points"
      />
    </Filter>
  );
};

const VariablesNotesListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  return (
    <MuiToolbar
      className={className}
      style={{ alignItems: 'center' }}
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      <Button
        startIcon={<CloudUpload />}
        color="primary"
        variant="text"
        href="#/variables_notes/import"
      >
        Import
      </Button>
    </MuiToolbar>
  );
};

const useStyles = makeStyles(() => ({
  tableCell: {
    maxWidth: 300,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export const VariablesNotesList = (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      filters={<VariablesNotesFilter />}
      actions={<VariablesNotesListActions />}
    >
      <Datagrid>
        <TextField source="alpha2" />
        <TextField source="twolet" />
        <TextField source="threelet" />
        <TextField cellClassName={classes.tableCell} source="method" />
        <TextField cellClassName={classes.tableCell} source="source" />
        <TextField cellClassName={classes.tableCell} source="data_quality" />
        <TextField cellClassName={classes.tableCell} source="imputation" />
        <TextField cellClassName={classes.tableCell} source="extrapolation" />
        <TextField cellClassName={classes.tableCell} source="data_points" />
        <TextField source="filename" />
        <EditButton />
        <ShowButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const VariablesNotesTitle = ({ record }) => {
  return (
    <span>
      Editing variables notes:{' '}
      <strong>
        {record ? `(${record.alpha2}) ${record.twolet} ${record.threelet}` : ''}
      </strong>
    </span>
  );
};

const VariablesNotesInputs = ({ record, ...rest }) => {
  const { data: countriesList, loading: countriesLoading } = useCountries();
  const { data: namesData, loading: namesLoading } = useNames();

  return (
    <SimpleForm warnWhenUnsavedChanges initialValues={record} {...rest}>
      <AutocompleteInput
        loading={countriesLoading}
        resettable
        source="alpha2"
        choices={countriesList}
        optionText={(record) =>
          record && `${record.alpha2} (${record.shortname})`
        }
        optionValue="alpha2"
      />
      <AutocompleteInput
        loading={namesLoading}
        resettable
        source="twolet"
        choices={namesData.uniqueY}
        optionText={(record) =>
          record && `${record.twolet} (${record.shortname})`
        }
        optionValue="twolet"
      />
      <AutocompleteInput
        loading={namesLoading}
        resettable
        source="threelet"
        choices={namesData.uniqueVar}
        optionText={(record) =>
          record && `${record.threelet} (${record.shortname})`
        }
        optionValue="threelet"
      />
      <TextInput fullWidth multiline source="method" />
      <TextInput fullWidth multiline source="source" />
      <TextInput multiline source="data_quality" />
      <TextInput multiline source="imputation" />
      <TextInput multiline source="extrapolation" />
      <TextInput multiline source="data_points" />
    </SimpleForm>
  );
};

export const VariablesNotesEdit = (props) => (
  <Edit title={<VariablesNotesTitle />} {...props}>
    <VariablesNotesInputs {...props} />
  </Edit>
);

export const VariablesNotesCreate = (props) => (
  <Create {...props}>
    <VariablesNotesInputs />
  </Create>
);

export const VariablesNotesShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField fullWidth source="twolet" />
      <TextField fullWidth source="threelet" />
      <TextField fullWidth source="method" />
      <TextField fullWidth source="source" />
      <TextField fullWidth source="data_quality" />
      <TextField fullWidth source="imputation" />
      <TextField fullWidth source="extrapolation" />
      <TextField fullWidth source="data_points" />
      <TextField fullWidth source="filename" />
    </SimpleShowLayout>
  </Show>
);
