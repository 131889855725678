import { useGetList } from 'react-admin';

const usePops = () => {
  const { data, ...rest } = useGetList(
    'variables_pops',
    {
      page: 1,
      perPage: 100,
    },
    { field: 'onepop', order: 'ASC' }
  );

  return { ...rest, data: Object.values(data) };
};

export default usePops;
