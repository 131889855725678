import React, {cloneElement} from 'react';
import {
    Show,
    ShowButton,
    SimpleShowLayout,
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    EditButton,
    DeleteButton,
    SimpleForm,
    TextInput,
    Filter,
    CreateButton,
    ExportButton,
    useListContext,
    AutocompleteInput, sanitizeListRestProps, SelectInput,
} from 'react-admin';
import {CloudUpload} from '@material-ui/icons';
import {Button, Toolbar as MuiToolbar} from '@material-ui/core';
import {JsonField, JsonInput} from 'react-admin-json-view';
import useCountries from '../../hooks/useCountries';
import useTypes from '../../hooks/useTypes';

const VariablesUnitsFilter = (props) => {
    const { data: typesList } = useTypes();
    const { data: countriesList } = useCountries();

    return (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn/>
            <SelectInput
                source="country"
                choices={countriesList}
                optionText="alpha2"
                optionValue="alpha2"
            />
            <SelectInput
                source="var_type"
                choices={typesList}
                optionText="onetype"
                optionValue="onetype"
            />
        </Filter>
    );
}

const VariablesUnitsListActions = (props) => {
    const {className, exporter, filters, maxResults, ...rest} = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total,
    } = useListContext();

    return (
        <MuiToolbar
            className={className}
            style={{alignItems: 'center'}}
            {...sanitizeListRestProps(rest)}>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <CreateButton basePath={basePath}/>
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
            <Button
                startIcon={<CloudUpload/>}
                color="primary"
                variant="text"
                href="#/variables_units/import"
            >
                Import
            </Button>
        </MuiToolbar>
    );
};

export const VariablesUnitsList = (props) => (
    <List
        {...props}
        filters={<VariablesUnitsFilter/>}
        actions={<VariablesUnitsListActions/>}
    >
        <Datagrid>
            <TextField source="country"/>
            <TextField source="var_type"/>
            <JsonField
                source="metadata"
                reactJsonOptions={{
                    collapsed: true,
                    name: 'metadata',
                    enableClipboard: false,
                    displayDataTypes: false,
                }}
            />
            <TextField source="filename"/>
            <EditButton/>
            <ShowButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

const VariablesUnitsTitle = ({record}) => (
    <span>
    Editing variable unit:{' '}
        <strong>{record ? `(${record.country})${record.var_type}` : ''}</strong>
  </span>
);

export const VariablesUnitsEdit = (props) => {
    const {data: countriesList, loading: countriesLoading} = useCountries();
    const {data: typesList, loading: typesLoading} = useTypes();

    return (
        <Edit title={<VariablesUnitsTitle/>} {...props}>
            <SimpleForm warnWhenUnsavedChanges>
                <AutocompleteInput
                    loading={countriesLoading}
                    resettable
                    source="country"
                    choices={countriesList}
                    optionText={(record) =>
                        record && `${record.alpha2} (${record.shortname})`
                    }
                    optionValue="alpha2"
                />
                <AutocompleteInput
                    loading={typesLoading}
                    resettable
                    source="var_type"
                    choices={typesList}
                    optionText={(record) =>
                        record && `${record.onetype} (${record.shortdes})`
                    }
                    optionValue="onetype"
                />
                <JsonInput
                    source="metadata"
                    reactJsonOptions={{
                        name: 'metadata',
                        enableClipboard: true,
                        displayDataTypes: false,
                    }}
                />
            </SimpleForm>
        </Edit>
    );
};

export const VariablesUnitsCreate = (props) => {
    const {data: countriesList, loading: countriesLoading} = useCountries();
    const {data: typesList, loading: typesLoading} = useTypes();

    return (
        <Create {...props}>
            <SimpleForm warnWhenUnsavedChanges>
                <AutocompleteInput
                    loading={countriesLoading}
                    resettable
                    source="country"
                    choices={countriesList}
                    optionText={(record) =>
                        record && `${record.alpha2} (${record.shortname})`
                    }
                    optionValue="alpha2"
                />
                <AutocompleteInput
                    loading={typesLoading}
                    resettable
                    source="var_type"
                    choices={typesList}
                    optionText={(record) =>
                        record && `${record.onetype} (${record.shortdes})`
                    }
                    optionValue="onetype"
                />
                <JsonInput
                    source="metadata"
                    reactJsonOptions={{
                        name: 'metadata',
                        enableClipboard: true,
                        displayDataTypes: false,
                    }}
                />
            </SimpleForm>
        </Create>
    );
};

export const VariablesUnitsShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="country"/>
            <TextField source="var_type"/>
            <JsonField
                source="metadata"
                addLabel
                reactJsonOptions={{
                    name: 'metadata',
                    enableClipboard: true,
                    displayDataTypes: false,
                }}
            />
            <TextField source="filename"/>
        </SimpleShowLayout>
    </Show>
);
