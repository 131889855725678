import * as React from 'react';
import { Box, Card, CardActions, Button, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import CodeIcon from '@material-ui/icons/Code';
import DescriptionIcon from '@material-ui/icons/Description';
import { makeStyles } from '@material-ui/core/styles';

import widLogo from './logo-widworld-blue-x2.png';

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(to top, #cfd9df 0%, ${theme.palette.primary.main} 100%)`,
    color: '#fff',
    padding: 20,
    marginTop: theme.spacing(2),
    marginBottom: '1em',
  },
  media: {
    background: `url(${widLogo}) center center / contain no-repeat`,
    marginLeft: 'auto',
  },
  actions: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
      flexWrap: 'wrap',
      '& a': {
        marginTop: '1em',
        marginLeft: '0!important',
        marginRight: '1em',
      },
    },
  },
}));

const Welcome = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Box display="flex">
        <Box flex="1">
          <Typography variant="h5" component="h2" gutterBottom>
            Welcome to the admin app of WID
          </Typography>
          <Box maxWidth="40em">
            <Typography variant="body1" component="p" gutterBottom>
              This is the admin of WID.world database (DEV). You can add,
              update, delete data from separate tables or bulk import with csv.
            </Typography>
          </Box>
          <CardActions className={classes.actions}>
            <Button
              variant="contained"
              href="https://wid.world"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<HomeIcon />}
            >
              WID.world website
            </Button>
            <Button
              variant="contained"
              href="#/documentation"
              startIcon={<CodeIcon />}
            >
              Tool Documentation
            </Button>
            <Button
              variant="contained"
              target="_blank"
              href="http://wid-docs.wid.world/"
              startIcon={<DescriptionIcon />}
            >
              DB Documentation
            </Button>
          </CardActions>
        </Box>

        <Box
          display={{ xs: 'none', sm: 'none', md: 'block' }}
          className={classes.media}
          width="16em"
          height="9em"
          overflow="hidden"
        />
      </Box>
    </Card>
  );
};

export default Welcome;
